import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

export default () => (
  <Layout>
    <h1>About me</h1>
    <section>
      Hi there, Im Jeff, I was born in the UK and came to the USA in 1989 on a
      two year assignment. My company decided to keep me so I have been living
      and working in the San Francisco Bay Area since that time. I also have a
      home in Cornwall UK which I like to visit often and while there I find
      myself way more productive because its just a lovely part of the world
      without the pace of SFBA. I love to cook hopefully some of my more
      successful recipes will make it to this site over time. Im a huge advocate
      of farm and water to table. A few years ago I wrote a fairly popular blog
      on the subject called the{' '}
      <Link external to="https://cupertinodiet.blogspot.com">
        Cupertino Diet
      </Link>{' '}
      sadly life got in the way and this has long since become dormant. I also
      enjoy eating food as well as cooking it so you might see some reviews here
      too. Beer and wine are also a big interest of mine always happy to pass on
      tips.
    </section>
    <section>
      Hope you enjoy this site if you are interested in what I do for a job then
      my checkout out my{' '}
      <Link external to="https://jeffmorgan.me">
        Resume
      </Link>
    </section>
    <Link to="/">&larr; back to home</Link>
  </Layout>
);
